.input-select
  position: relative

  .input
    appearance: none
    color: $secondary

    &:valid
      color: $dark

  &__arrow
    position: absolute
    right: 16px
    display: block
    pointer-events: none

    img
      display: block