@import '../_vars.sass'
@import '../mixins/_transition.sass'

aside
  nav
    width: 105px
    min-height: 100%
    background: $gray-3

    > ul
      > li
        position: relative
        height: 88px

        span
          position: relative
          z-index: 3
          display: flex
          flex-direction: column
          align-items: center
          justify-content: center
          width: 100%
          height: 100%
          font-size: rem(12px)
          color: $gray-7
          background: $gray-3
          @include transition()

          &:hover
            color: $light

          svg
            margin-bottom: 3px

            .fill
              fill: $secondary
              @include transition()

        ul
          position: absolute
          top: 0
          left: 100%
          z-index: 2
          display: flex
          height: 100%
          background: $primary
          transform: translateX(-100%)
          @include transition()

          li
            width: 100px
            height: 100%
            border-left: 1px solid $primary

            &:first-child
              border-left: 0

            a
              display: flex
              justify-content: center
              align-items: center
              width: 100%
              height: 100%
              font-size: rem(12px)
              font-weight: bold
              text-align: center
              color: $light
              background: lighten($primary, 14.5%)

              &:hover
                background: $primary

        &:hover
          span
            color: $light
            background: rgba($primary, 1)

            svg
              .fill
                fill: $light

          ul
            transform: translateX(0%)
