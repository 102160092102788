.m-0
  margin: 0 !important

.m-5
  margin: 5px !important

.m-10
  margin: 10px !important

.m-15
  margin: 15px !important

.ml-0
  margin-left: 0 !important

.ml-5
  margin-left: 5px !important

.ml-10
  margin-left: 10px !important

.ml-15
  margin-left: 15px !important

.mr-0
  margin-right: 0 !important

.mr-5
  margin-right: 5px !important

.mr-10
  margin-right: 10px !important

.mr-15
  margin-right: 15px !important

.mx-0
  margin-left: 0 !important
  margin-right: 0

.mx-5
  margin-left: 5px !important
  margin-right: 5px

.mx-10
  margin-left: 10px !important
  margin-right: 10px

.mx-15
  margin-left: 15px !important
  margin-right: 15px

.mt-0
  margin-top: 0 !important

.mt-5
  margin-top: 5px !important

.mt-10
  margin-top: 10px !important

.mt-15
  margin-top: 15px !important

.mb-0
  margin-bottom: 0 !important

.mb-5
  margin-bottom: 5px !important

.mb-10
  margin-bottom: 10px !important

.mb-15
  margin-bottom: 15px !important

.my-0
  margin-top: 0 !important
  margin-bottom: 0

.my-5
  margin-top: 5px !important
  margin-bottom: 5px

.my-10
  margin-top: 10px !important
  margin-bottom: 10px

.my-15
  margin-top: 15px !important
  margin-bottom: 15px