form
  fieldset
    border: none

    legend
      margin-bottom: 8px
      font-size: rem(20px)
      font-weight: 300
      line-height: 1.3
      color: $dark

    & + fieldset
     margin-top: 35px
