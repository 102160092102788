body
  position: relative
  display: flex
  flex-direction: column
  min-height: 100vh
  font-family: 'ProximaNova', sans-serif
  font-size: rem(16px)
  // color: var(--text-color)
  // background: var(--bg-color)

input,
textarea,
button
  font-family: 'ProximaNova', sans-serif

body
  display: flex
  flex-direction: column
  background: var(--bg-login)

.wrap
  display: flex
  align-items: stretch
  flex: 1 1 auto

  main
    flex: 1 1 auto

.container
  width: 100%
  padding-left: 20px
  padding-right: 20px

.container-sm
  width: 100%
  padding-left: 40px
  padding-right: 40px

a
  text-decoration: none
  color: inherit
  @include transition

form
  display: block
  max-width: 100%
