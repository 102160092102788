$white: #FFFFFF
$black: #030309
$black-light: #303030
$green: #56AF79 // #00c473 //#00E988
$green-light: rgba(170, 255, 196, 0.2)
$red: #EB5757
$red-light: rgba(255, 0, 0, 0.1) // rgba(175, 86, 86, 0.1)
$yellow: rgba(255, 100, 51, 1) // #FFD500
$yellow-light: rgba(255, 188, 168, 0.2) // #FFF9C4
$blue: #3242B9
$gray: #8e8e8e

$colors: () !default
$colors: map-merge(("red":$red,"yellow":$yellow,"green":$green,"white":$white,"black":$black,"blue":$blue, "green-light": $green-light, "red-light": $red-light, "yellow-light": $yellow-light), $colors)

$gray-1: #F8F8F8
$gray-2: #F1EFF2
$gray-3: #E4E0E6
$gray-4: #C7BBC9
$gray-5: #A79CAC
$gray-6: #87798D
$gray-7: #38303B
$gray-8: #2D212F

$gray-colors: () !default
$gray-colors: map-merge(('gray-1': $gray-1, 'gray-2': $gray-2, 'gray-3': $gray-3, 'gray-4': $gray-4, 'gray-5': $gray-5, 'gray-6': $gray-6, 'gray-7': $gray-7, 'gray-8': $gray-8,), $gray-colors)

// 374359
$primary: $green
$primary-light: lighten($green, 14.5%)
$primary-dark: darken($green, 5%)
$secondary: #8e8e8e // $gray-4
$success: #6FCF97
$danger: #EB5757
$light: $gray-1
$dark: #131416
$warning: #FF6433 // $yellow

$text-primary: $white

$theme-colors: () !default
$theme-colors: map-merge(('primary': $primary, 'primary-light': $primary-light, 'primary-dark': $primary-dark, 'secondary': $gray-4, 'success': $green, 'danger': $red, 'light': $gray-1, 'dark': $gray-8, 'warning': $warning, 'text-primary': $text-primary), $theme-colors)

$font-family-heading: "ProximaNova"
$font-family-paragraph: "ProximaNova"

$font-weight-medium: 600
$font-weight-bold: 800
$font-weight-semibold: 700
$font-weight-regular: 400

$font-size-xxs: rem(10px)
$font-size-xs: rem(12px)
$font-size-sm: rem(14px)
$font-size-md: rem(16px)
$font-size-lg: rem(24px)
$font-size-xl: rem(32px)
$font-size-display: rem(64px)

\:root
  @each $color, $value in $colors
    --#{$color}: #{$value}

  @each $color, $value in $gray-colors
    --#{$color}: #{$value}

  @each $color, $value in $theme-colors
    --#{$color}: #{$value}

  --font-family-heading: $font-family-heading
  --font-family-paragraph: $font-family-paragraph
  --font-weight-medium: $font-weight-medium
  --font-weight-bold: $font-weight-bold
  --font-weight-semibold: $font-weight-semibold
  --font-weight-regular: $font-weight-regular

  --font-size-xxs: $font-size-xxs
  --font-size-xs: $font-size-xs
  --font-size-sm: $font-size-sm
  --font-size-md: $font-size-md
  --font-size-lg: $font-size-lg
  --font-size-xl: $font-size-xl
  --font-size-display: $font-size-display
