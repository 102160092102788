@import '_reset'
@import '_vars'
@import '_fonts'
@import '_colors.scss'

@import 'mixins/_placeholder'
@import 'mixins/_transition'
@import 'mixins/_units'

@import 'helpers/_bg'
@import 'helpers/_flex'
@import 'helpers/_grid'
@import 'helpers/_margin'
@import 'helpers/_padding'
@import 'helpers/_text'
@import 'helpers/_units'

@import '_template'
//@import 'components/*'
@import 'components/_aside'
@import 'components/_btn'
@import 'components/_container-separator'
@import 'components/_content-dashboard'
@import 'components/_content-nas'
@import 'components/_content-nav'
@import 'components/_content'
@import 'components/_costumer-search'
@import 'components/_day-tasks'
@import 'components/_dropdown'
@import 'components/_footer'
@import 'components/_form'
@import 'components/_input-group'
@import 'components/_input-select'
@import 'components/_input'
@import 'components/_list-cards'
@import 'components/_main-header'
@import 'components/_modal'
@import 'components/_my-wallet'
@import 'components/_notifications'
@import 'components/_panel'
@import 'components/_table-clean'
@import 'components/_table-group'
@import 'components/_table-list'
@import 'components/_title'
@import 'components/_user-menu'
@import 'components/_well'
@import 'components/_file_div'

.v-application
  font-family: 'ProximaNova' !important
  color: var(--text-on-bg) !important

::-webkit-scrollbar
  width: 7px

::-webkit-scrollbar-track
  background: #fff
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1)

::-webkit-scrollbar-thumb
  background: #ccc
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.2)

::-webkit-scrollbar-thumb:hover
  background: #aaa

::-webkit-scrollbar-thumb:active
  background: #888
  -webkit-box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.3)

.no-overflow-x
  overflow-x: hidden
