.dropdown
  position: relative
  // z-index: 5

  $dropdown: #{&}

  &__content
    position: absolute
    top: 100%
    z-index: 20
    min-width: 190px
    border-radius: 3px
    background: $white
    box-shadow: 0 15px 30px 0 rgba($secondary, 0.53)
    opacity: 0
    visibility: hidden
    @include transition

    &:before
      content: ""
      position: absolute
      bottom: 100%
      display: block
      width: 0
      height: 0
      border-style: solid
      border-width: 0 10.5px 6px 10.5px
      border-color: transparent transparent $white transparent

    > ul
      > li

        &:not(:first-child)
          border-top: 1px solid $gray-2

        ul
          padding: 20px 26px

          li

            &:not(:first-child)
              margin-top: 6px

            a
              color: $gray-5

  &-right
    #{$dropdown}__content
      right: -20px

      &:before
        right: 11px

  &-left
    #{$dropdown}__content
      left: -20px

      &:before
        left: 11px

  &.active #{$dropdown}__content,
  &__toggle:focus + #{$dropdown}__content
    top: calc(100% + 20px)
    opacity: 1
    visibility: visible