.content
  &-header
    display: flex
    padding-top: 33px
    padding-bottom: 36px

    &__content
      position: relative
      display: flex
      align-items: center

      h1
        position: relative
        display: block
        margin-right: 18px
        padding-bottom: 9px
        font-size: rem(30px)
        font-weight: 300
        line-height: 1
        color: $dark

        &:after
          content: ""
          position: absolute
          top: 100%
          left: 0
          display: block
          width: 97px
          height: 5px
          border-radius: 10px
          background: $primary

      span
        font-size: rem(14px)
        color: $gray-5

      small
        position: absolute
        bottom: 100%
        left: 0
        font-size: rem(12px)
        font-weight: 300
        color: $dark

    &__back
      margin-right: 16px

      .btn
        height: 100%

  &-body
    padding-bottom: 33px