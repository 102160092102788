.table-list
  width: 100%
  border-spacing: 0

  tr
    &:not(:first-child)
      td
        border-top: 1px solid $gray-3

  td
    height: 50px
    padding-left: 10px
    padding-right: 10px
    // padding-bottom: 16px
    font-size: rem(12px)
    text-align: center
    vertical-align: middle

    strong
      font-size: rem(14px)