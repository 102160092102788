.panel
  height: 100%
  padding: 15px 20px
  background: $gray-2

  $panel: #{&}

  & + .panel
    margin-top: 10px

  &-title
    margin-bottom: 10px
    font-size: rem(16px)
    font-weight: normal
    line-height: 1.3

  &-closed
    padding: 20px

    #{$panel}-title
      margin-bottom: 0