.bg
  &-white
    background: $white !important
  &-light
    background: $light !important
  &-gray-1
    background: $gray-1 !important
  &-gray-2
    background: $gray-2 !important
  &-gray-3
    background: $gray-3 !important
  &-gray-4
    background: $gray-4 !important
  &-gray-5
    background: $gray-5 !important
  &-gray-6
    background: $gray-6 !important
  &-gray-7
    background: $gray-7 !important
  &-gray-8
    background: $gray-8 !important

  &-danger
    background: $red-light !important
    color: $red !important
    font-weight: bold !important
  &-warning
    background: $yellow-light !important
    color: $warning !important
    font-weight: bold !important
  &-success
    background: $green-light !important
    color: $success !important
    font-weight: bold !important

td.bg
  &-light
    &:after
      background: $light !important
