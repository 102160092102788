.text-left
  text-align: left !important

.text-right
  text-align: right !important

.text-center
  text-align: center !important

.text-normal
  font-weight: normal !important

.text-bold
  font-weight: bold !important

.text-spaced
  letter-spacing: 0.3em !important

.text-italic
  font-style: italic

.text-uppercase
  text-transform: uppercase !important

.text-underline
  text-decoration: underline !important

.text-primary
  color: var(--primary) !important

.text-secondary
  color: var(--secondary) !important

.text-success
  color: $success !important
.text-green
  color: $green !important
.text-purple
  color: #7000FF

.text-danger
  color: #C42929 !important // $danger

.text-warning
  color: $warning !important

.text-light
  color: $light !important

.text-dark
  color: $dark !important

.text-black
  color: $black !important
.text-black-light
  color: $black-light !important
.text-white
  color: $white !important
.text-disabled
  color: rgba(142, 142, 142, 0.6) !important

.text-xxs
  font-size: $font-size-xxs // 10
.text-xs
  font-size: rem(12px) // 12
.text-sm
  font-size: rem(14px) // 14
.text-md
  font-size: $font-size-md // 16
  line-height: 125%
.text-lg
  font-size: rem(24px) // 24
.text-xl
  font-size: $font-size-xl // 32
.text-display
  font-size: $font-size-display // 64

.icon-lg
  font-size: rem(48px) !important

.text-color-900
  color: $neutral-color-900 !important
.text-color-600
  color: $neutral-color-600 !important
.text-color-400
  color: $neutral-color-400 !important
.text-color-300
  color: $neutral-color-300 !important
.text-color-200
  color: $neutral-color-200 !important
.text-color-100
  color: $neutral-color-100 !important

.text-heading-bold
  font-weight: $font-weight-bold
  font-size: rem(18px)
  line-height: 120%
.text-heading-display
  font-weight: $font-weight-semibold
  font-size: rem(30px)
  line-height: 160%
.text-heading-x-large
  font-weight: $font-weight-semibold
  font-size: rem(28px)
  line-height: 150%
.text-heading-large
  font-weight: $font-weight-semibold
  font-size: rem(18px)
  line-height: 140%
.text-heading-medium
  font-weight: $font-weight-semibold
  font-size: rem(16px)
  line-height: 140%
.text-heading-medium-lighten
  font-weight: $font-weight-medium
  font-size: rem(16px)
  line-height: 140%
.text-heading-small
  font-weight: $font-weight-semibold
  font-size: rem(14px)
  line-height: 140%
.text-heading-small-medium
  font-weight: $font-weight-medium
  font-size: rem(14px)
  line-height: 140%
.text-heading-large
  font-weight: $font-weight-semibold
  font-size: rem(18px)
  line-height: 140%
.text-overline // font-family: Graphik
  font-weight: $font-weight-medium
  font-size: rem(12px)
  line-height: 133%
  text-transform: uppercase
.text-status
  font-weight: $font-weight-medium
  font-size: rem(10px)
  line-height: 133%
.text-subtitle-small
  font-weight: $font-weight-medium
  font-size: rem(13px)
  line-height: 133%
.text-paragraph-large
  font-weight: $font-weight-regular
  font-size: rem(12.8px)
  line-height: 140%
.text-paragraph-medium
  font-weight: $font-weight-regular
  font-size: rem(14px)
  line-height: 140%
.text-paragraph-small
  font-weight: $font-weight-regular
  font-size: rem(16px)
  line-height: 133%

.font-medium
  font-weight: $font-weight-medium !important
.font-bold
  font-weight: $font-weight-bold !important
.font-semibold
  font-weight: $font-weight-semibold !important
.font-regular
  font-weight: $font-weight-regular !important

.uppercase
  text-transform: uppercase

.font-general-sans
  font-family: "GeneralSans"
.font-proxima-nova
  font-family: "ProximaNova"
