.title
  position: relative
  margin-bottom: 5px
  font-size: rem(30px)
  font-weight: 300
  color: $dark

  &:after
    content: ""
    display: block
    width: rem(97px)
    height: rem(5px)
    border-radius: rem(10px)
    background: $primary

