.input-group
  position: relative
  display: flex
  align-items: center
  width: 100%

  & + .input-group
    margin-top: 10px

  .input
    width: 100%
    height: 42px
    padding-right: 40px

  textarea
    height: 84px !important
    padding-top: 10px
    padding-bottom: 10px

  label
    min-width: 272px
    width: 100%

    > span
      display: inline-block
      margin-bottom: 7px
      font-size: rem(12px)
      line-height: 1.33
      color: $dark

  .btn
    position: absolute
    right: 16px

  &-lg
    .input
      height: 80px

    textarea
      height: 160px !important
      padding-top: 30px

  &-sm
    label
      min-width: 0

    .input
      height: 24px
      min-width: 0
      padding: 0 12px
      font-size: rem(10px)

    textarea
      height: 48px !important
      padding-top: 5px