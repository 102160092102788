.costumer-search
  display: flex
  width: 100%
  overflow: hidden
  padding-top: 20px
  padding-bottom: 40px
  padding-right: 0
  background: $light

  hr
    display: block
    border: none
    border-left: 1px solid $gray-2
    margin-top: -20px
    margin-bottom: -40px

  &__search
    flex: 0 0 calc(28% - 40px)
    padding-top: 24px
    padding-right: 40px

    input
      background: $gray-2

  &__costumers
    display: flex
    flex: 1 1 auto
    flex-wrap: nowrap
    padding-left: 38px
    overflow: hidden

    p
      margin-bottom: 8px
      font-size: rem(12px)
      color: $dark

    &__list
      flex: 1 1 auto
      position: relative
      margin-left: 10px
      overflow: hidden

      &__nav
        position: absolute
        top: 24px
        bottom: 0px
        right: 26px
        display: flex
        align-items: center

      &:before
        content: ""
        position: absolute
        right: 0
        top: 0
        display: block
        width: 50%
        height: 100%
        background: linear-gradient(to right, rgba($light, 0.01) 0%, $light 85%)
        pointer-events: none