.content-dashboard

  &__block
    position: relative
    height: 100%
    padding: 24px 30px
    background: $light
    box-shadow: 0 3px 6px 0 rgba($secondary, 0.13)

    >* + h2
      margin-top: 15px

    h2
      margin-bottom: 31px
      font-size: rem(12px)
      font-weight: bold
      color: $dark

      small
        font-size: rem(12px)
        font-weight: normal
        color: $gray-5

      a
        text-decoration: underline

      & + h3
        margin-top: -28px

      & + hr
        margin-top: -15px

      & + h3
        margin-bottom: 12px
        font-size: rem(12px)
        font-weight: normal
        color: $gray-5

    p
      margin-bottom: 16px
      font-size: rem(12px)
      line-height: 1.33
      color: $dark

      strong
        font-weight: bold

    hr
      margin-top: 16px
      margin-bottom: 16px
      border: none
      border-bottom: 1px solid $gray-3

    table
      width: 100%
      border-spacing: 0

      thead
        padding-bottom: 10px

        & + tbody
          tr
            &:first-child
              td
                padding-top: 17px

      tr
        &:first-child
          td
            padding-top: 0

      th
        padding-bottom: 9px
        border-bottom: 1px solid $gray-3
        font-size: rem(10px)
        text-align: center
        color: $secondary

        &:first-child
          text-align: left

      td
        padding-top: 17px
        font-size: rem(12px)
        text-align: center

        &:first-child
          text-align: left

    &[data-title]
      margin-top: 28px
      height: calc(100% - 28px)

      &:before
        content: attr(data-title)
        position: absolute
        top: -28px
        left: 0
        font-size: rem(12px)
        font-weight: bold
        color: $dark