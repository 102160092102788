.p-0
  padding: 0 !important

.p-5
  padding: 5px !important

.p-10
  padding: 10px !important

.p-15
  padding: 15px !important

.p-20
  padding: 20px !important

.pl-0
  padding-left: 0 !important

.pl-5
  padding-left: 5px !important

.pl-10
  padding-left: 10px !important

.pl-15
  padding-left: 15px !important

.pl-20
  padding-left: 20px !important

.pr-0
  padding-right: 0 !important

.pr-5
  padding-right: 5px !important

.pr-10
  padding-right: 10px !important

.pr-15
  padding-right: 15px !important

.pr-20
  padding-right: 20px !important

.px-0
  padding-left: 0 !important
  padding-right: 0

.px-5
  padding-left: 5px !important
  padding-right: 5px

.px-10
  padding-left: 10px !important
  padding-right: 10px

.px-15
  padding-left: 15px !important
  padding-right: 15px !important

.px-20
  padding-left: 20px !important
  padding-right: 20px !important

.pt-0
  padding-top: 0 !important

.pt-5
  padding-top: 5px !important

.pt-10
  padding-top: 10px !important

.pt-15
  padding-top: 15px !important

.pt-20
  padding-top: 20px !important

.pb-0
  padding-bottom: 0 !important

.pb-5
  padding-bottom: 5px !important

.pb-10
  padding-bottom: 10px !important

.pb-15
  padding-bottom: 15px !important

.pb-20
  padding-bottom: 20px !important

.py-0
  padding-top: 0 !important
  padding-bottom: 0 !important

.py-5
  padding-top: 5px !important
  padding-bottom: 5px !important

.py-10
  padding-top: 10px !important
  padding-bottom: 10px !important

.py-15
  padding-top: 15px !important
  padding-bottom: 15px !important

.py-20
  padding-top: 20px !important
  padding-bottom: 20px !important