.modal
  position: fixed
  top: 0
  left: 0
  z-index: 99
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  backdrop-filter: blur(7px)
  background-color: rgba($dark, 0.11)
  transform: translateZ(0)

  $modal: #{&}

  &__box
    position: relative
    display: flex
    flex-direction: column
    width: 100%
    max-width: 706px
    height: auto
    max-height: 90vh
    padding: 34px 30px 50px
    border-radius: 3px
    background: $light
    box-shadow: 0 15px 30px 0 rgba(197, 191, 200, 0.53)

  &__close
    position: absolute
    top: 25px
    right: 25px

  &__header
    .content-header
      padding-top: 0

  &__content
    overflow: auto

  &-large
    #{$modal}__box
      max-width: calc(100% - 130px)
