.notifications
  position: fixed
  top: 0
  left: 0
  z-index: 100
  width: 100%
  height: 100%
  pointer-events: none

  &__content
    //position: absolute
    top: 0
    left: calc(100%)
    z-index: 2
    display: flex
    flex-direction: column
    width: 300px
    height: 100%
    overflow: hidden
    background: $dark
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0)
    transition: all 0.2s ease-out

  &__header
    display: flex
    align-items: center
    height: 60px
    padding: 0 40px 0 37px
    color: $light
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

    svg
      display: block

      .fill
        fill: $light

  &__list
    flex: 1 1 auto
    overflow: auto

    &__item
      padding: 21px 40px 25px 37px

      $item: #{&}

      & + #{$item}
        border-top: 1px solid #3f3941

      &__header
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 5px
        font-size: rem(12px)
        font-weight: bold
        color: $primary

        svg
          .fill
            fill: $light

      &__body
        padding-right: 50px
        font-size: rem(14px)
        line-height: 1.29
        color: $light

  &__bg
    position: absolute
    top: 0
    left: 0
    z-index: 1
    width: 100%
    height: 100%
    backdrop-filter: blur(0px)
    background-color: rgba($dark, 0)
    transform: translateZ(0)
    transition: all 0.2s linear

    label
      display: block
      width: 100%
      height: 100%

#notificationsToggle
  display: none

  &:checked
    & + .notifications
      pointer-events: all

      .notifications
        &__content
          left: calc(100% - 300px)
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

        &__bg
          background-color: rgba($dark, 0.11)
          backdrop-filter: blur(7px)
