.content-nav
  padding-top: 33px
  padding-bottom: 36px

  ul
    display: flex
    align-items: center

    li
      margin-right: 31px
      font-size: rem(14px)

      a
        position: relative
        display: block
        line-height: 1
        color: $gray-5

        &:after
          content: ""
          position: absolute
          top: 100%
          left: 0
          display: block
          width: 97px
          height: 5px
          border-radius: 10px
          background: $primary
          opacity: 0
          @include transition()

        &:hover
          color: $dark

      &.active
        font-size: rem(30px)

        a
          padding-bottom: 9px
          color: $dark

          &:after
            opacity: 1

