.table-clean
  width: 100%
  border-spacing: 0

  thead
    padding-bottom: 10px

    & + tbody
      tr
        &:first-child
          td
            padding-top: 17px

  tr
    &:first-child
      td
        padding-top: 0

  th
    padding-bottom: 9px
    border-bottom: 1px solid $gray-3
    font-size: rem(10px)
    text-align: center
    color: $secondary

    &:first-child
      text-align: left

  td
    padding-top: 17px
    font-size: rem(12px)
    text-align: center

    &:first-child
      text-align: left