.table-group
  display: flex

  $table-group: #{&}

  &__table
    flex: 1 1 auto

    & + #{$table-group}__table
      margin-left: 44px
      padding-left: 44px
      border-left: 1px solid $gray-3

    &__title
      margin-bottom: 14px
      font-size: rem(12px)
      font-weight: bold
      color: $dark
