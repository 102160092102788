.well
  display: flex
  align-items: center
  padding: 10px 20px
  border: 1px solid $primary
  border-radius: 3px

  strong
    margin-right: 8px
    font-size: rem(30px)
    font-weight: bold
    color: $dark