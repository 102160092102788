.user-menu
  position: relative
  align-self: stretch
  min-width: 210px

  $user-menu: #{&}

  &__info
    position: relative
    z-index: 2
    display: flex
    width: 100%
    height: 100%
    background: $gray-7
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0)
    @include transition()

  &__toggle
    display: flex
    align-items: center
    width: 100%
    height: 100%
    padding-left: 30px
    padding-right: 40px
    font-size: rem(13px)
    font-weight: 300
    white-space: nowrap
    user-select: none

    span
      flex: 1 1 auto

    svg
      .fill
        fill: $light

      &:first-child
        margin-right: 13px

      &:last-child
        margin-left: 12px
        transform: rotate(180deg)

  &__nav
    position: absolute
    top: 100%
    z-index: 1
    display: flex
    flex-direction: column
    width: 100%
    padding: 30px 20px 35px 30px
    background: $gray-7
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0)
    transform: translateY(-100%)
    @include transition()

    ul
      display: flex
      flex-direction: column
      align-items: flex-start !important

      & + ul
        margin-top: 35px

      li
        height: auto !important
        margin-right: 0 !important

        & + li
          margin-top: 10px

        a
          text-align: left !important
          font-size: rem(12px) !important

    svg
      align-self: flex-end
      margin-top: 20px
      opacity: 0.3

      .fill
        fill: $gray-6



  #user-menu-toggle
    display: none

    &:checked
      & + #{$user-menu}__info
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)

        svg
          &:last-child
            transform: rotate(0deg)

        & + #{$user-menu}__nav
          transform: translateY(0%)
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)