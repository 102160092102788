@import "@design/_fonts.sass"

@import '~normalize.css/normalize.css'
@import '~nprogress/nprogress.css'
@import './design/index.sass'
// @import './design/style'

.subhead
  margin-bottom: 8px
  font-size: rem(20px)
  font-weight: 300
  line-height: 1.3
  color: var(--text-on-bg)
  font-weight: 500
  text-transform: uppercase

.subtitle
  margin-bottom: 31px
  font-size: 0.75rem
  font-weight: bold
  color: var(--text-on-bg)

#nprogress .bar
  background: var(--primary)

#nprogress .spinner-icon
  border-top-color: var(--primary)
  border-left-color: var(--primary)
