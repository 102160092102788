@for $i from 1 through 12
  .col-#{$i}
    flex: 0 0 percentage($i / 12)
    max-width: percentage($i / 12)
    position: relative
    width: 100%
    padding-left: 10px
    padding-right: 10px

@for $i from 1 through 12
  .offset-#{$i}
    margin-left: percentage($i / 12)

.col
  position: relative
  flex-basis: 0
  flex-grow: 1
  width: 100%
  max-width: 100%
  padding-left: 10px
  padding-right: 10px

.col-auto
  position: relative
  flex: 0 0 auto
  width: auto
  max-width: none
  padding-left: 10px
  padding-right: 10px

.row
  display: flex
  flex-wrap: wrap
  margin-right: -10px
  margin-left: -10px

  & + .row
    margin-top: 20px

  &-thin
    margin-left: -5px
    margin-right: -5px

    >[class^="col"]
      padding-left: 5px
      padding-right: 5px

    & + .row-thin
      margin-top: 10px