.list-cards
  margin-top: 40px

  &__card
    height: 80px
    overflow: hidden
    // margin-top: 20px
    border-radius: 3px
    // background: $light
    // box-shadow: 0 3px 6px 0 rgba($secondary, 0.13)

    &.active
      height: auto
      overflow: visible
      padding-bottom: 30px

    &:first-child
      margin-top: 0

    &__header
      display: flex
      align-items: center
      justify-content: space-between
      height: 70px
      margin-bottom: 5px

      &__title
        font-size: rem(16px)
        font-weight: bold
        line-height: 1
        color: $dark
        margin-bottom: 10px

      &__resume
        display: flex
        align-items: center
        font-size: rem(10px)
        font-weight: bold
        letter-spacing: 0.5px
        color: $secondary

        p
          margin-left: 20px

          span
            color: $gray-6

        button
          margin-left: 20px

    &__footer
      margin-top: 30px

    &__table
      width: 100%
      table-layout: auto
      border-collapse: collapse

      th
        padding: 0 16px 8px !important
        text-align: left !important
        font-size: rem(10px) !important
        font-weight: bold !important
        text-transform: uppercase !important
        color: $secondary !important

        &:first-child
          padding-left: 20px !important

        &:last-child
          padding-left: 35px !important
          padding-right: 20px !important

      td
        padding: 0 0 11px
        font-size: rem(14px)

        &:first-child
          // padding-left: 20px
          >div
            padding-left: 20px

        &:last-child
          > div
            justify-content: space-between
            width: 205px
            padding-left: 35px
            padding-right: 20px

        &.fit
          width: 1%
          white-space: nowrap

        strong
          font-weight: bold

        small
          font-size: rem(12px)

        > div
          position: relative
          display: flex
          align-items: center
          min-height: 62px
          padding: 0 16px
          background: $gray-2
          box-shadow: 0 3px 6px 0px rgba(197, 191, 200, 0.13)

          &:before
            content: ""
            position: absolute
            top: 0
            right: 0
            z-index: 1
            display: block
            width: 6px
            height: 100%
            background: inherit
            pointer-events: none

        .input-checkbox
          span
            max-width: 30px
