.input
  // min-width: 272px
  padding: 0 30px
  border: 0
  border-radius: 3px
  font-size: rem(15px)
  // color: var(--primary)
  background: $gray-3
  resize: none
  color: var(--text-on-bg) !important
  @include placeholder
    // color: rgba(106, 112, 134, .4)
    color: var(--text-on-bg)

  &-checkbox
    display: inline-flex
    align-items: center

    & + .input-checkbox
      margin-left: 15px

    input
      position: relative
      display: block
      width: 16px
      height: 16px
      border: 1px solid $secondary
      border-radius: 3px
      appearance: none

      &:after
        content: ""
        position: absolute
        left: 2px
        top: -2px
        display: block
        width: 15px
        height: 7px
        border: 2px solid var(--primary)
        border-top: 0
        border-right: 0
        transform: rotate(-45deg)
        opacity: 0
        visibility: hidden
        @include transition

      &:checked
        &:after
          opacity: 1
          visibility: visible

      &:disabled
        pointer-events: none

        & + span
          color: var(--primary)
          pointer-events: none

    span
      display: block !important
      margin-left: 7px !important
      margin-bottom: 0 !important
      font-size: rem(10px)
      line-height: 1 !important
      white-space: normal !important
      color: var(--primary)
      user-select: none !important

.icon-primary
  .v-input__append-inner
    color: var(--primary)

.v-input--selection-controls__ripple,
.v-input__icon i
  color: var(--primary) !important
.v-text-field:not(.error--text) .v-input__slot::before,
.theme--light.v-text-field:not(.error--text) > .v-input__control > .v-input__slot:before,
.theme--light.v-text-field.v-input--is-disabled > .v-input__control > .v-input__slot:before
  border-color: var(--primary) !important
.v-text-field
  --bg-color: #FFF
  &.v-text-field--enclosed .v-input__append-inner
    margin-top: 12px
  &.v-input--is-disabled
    opacity: 0.4
  &.error--text
    --border-color: var(--danger)
  &.v-input--is-focused:not(.error--text)
    --bg-color: #FFF
    .v-input__slot
      //border: 1px solid var(--primary) !important
  &.v-select
    .v-select__selections
      max-height: fit-content
      min-height: 42px
      display: flex
      align-items: center
      padding: 0 !important
    .v-input__slot
      max-height: min-content
  .v-text-field__slot input, .v-select__selection, .v-select__slot input
    font-weight: 400
    font-size: 0.875rem
    color: var(--text-on-bg) !important
    font-family: 'ProximaNova'
    font-feature-settings: 'ss02' on
  .v-label
    //transform: translateY(-35px)
    font-family: 'Open Sans', 'GeneralSans', 'ProximaNova'
    font-weight: 700
    font-size: 0.75rem
    padding-right: 6px
    padding-top: 1px
    // background: #fff
    color: var(--primary)
    font-feature-settings: 'ss02' on
  .v-input__slot
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
    // background-color: var(--bg-color)
    border-radius: 4px
    min-height: 40px !important
    .v-progress-linear
      margin-top: 4px
      height: 3px !important
      border-radius: 2px
    input
      padding: 8px 0px
  .v-select__selections
    padding: 0px 25px
    input
      padding-left: 0px
.v-menu__content:has(.v-select-list)
  border-radius: 4px
  border: 1px solid rgba(142, 142, 142, 0.5)
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3)
  margin-top: 8px
  .v-list-item
    &:hover
      .v-list-item__title
        color: var(--primary) !important
    &.v-list-item--active .v-list-item__title
      color: var(--primary) !important
    &.v-list-item--link:before
      background-color: var(--primary) !important
    &__title
      font-family: 'GeneralSans', 'ProximaNova'
      font-weight: 600
      font-size: 0.875rem
      color: #8e8e8e

// checkbox, radio
.v-input--checkbox, .v-input--radio-group
  .v-icon.mdi-checkbox-blank-outline, .mdi-radiobox-blank
    color: #8e8e8e
  .v-label
    color: #8e8e8e
