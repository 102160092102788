$btn-border-radius: 4px

.v-btn:not(.v-btn--round)
  border-radius: 4px !important
  padding: 12px 8px !important
  box-shadow: none !important
  gap: 8px
  font-size: rem(16px)
  font-weight: 600 // 700
  line-height: 125%
  // &:not(.v-size--small)
  //   height: rem(48px) !important
  // &.v-size--small
  //   height: rem(20px) !important

.btn
  //display: inline-block
  // width: rem(175px)
  height: rem(42px)
  padding: 12px 8px
  // height: rem(48px)
  // min-height: fit-content
  // min-width: max-content
  width: max-content
  max-width: 175px
  gap: 8px
  word-break: break-word
  border: 0
  border-radius: 4px
  font-family: "ProximaNova", "Open Sans", 'GeneralSans'
  font-size: rem(14px)
  font-weight: 600 // 700
  line-height: 100%
  background: none
  text-align: center
  @include transition
  cursor: pointer

  strong
    font-weight: bold

  & + .btn
    margin-left: 10px
  &:hover
    background-color: $neutral-color-200

  &-underlined
    color: $dark
    background: transparent
    border: none
    display: block
    outline: none
    // margin-bottom: 10px
    // height: rem(32px)

    position: relative
    transition: 0.3s
    padding: 10px
    // padding-bottom: 0
    &:before
      content: ''
      border-bottom: 1px solid $dark
      padding: 0 15px
      position: absolute
      bottom: 0
      left: 0
      right: 0
      margin: 0 auto
      width: 0
      transition: 0.3s

    &:hover:before
      width: 100%
      padding: 0
      border-bottom-color: var(--primary)

  &-primary
    color: var(--text-on-primary)
    background: var(--primary)

    &:hover
      color: var(--text-on-primary)
      background: var(--primary-dark)

    &.btn-text
      background: initial
      color: var(--primary) !important
      &:hover
        color: var(--primary-dark) !important
        background-color: $neutral-color-200

  &-outline-primary
    border: 1.5px solid var(--primary)
    color: var(--primary)
    background: rgba(var(--primary), 0)

    &:hover
      color: var(--text-on-primary)
      background: var(--primary)

  &-secondary
    color: $white
    background: $secondary

    &:hover
      background: darken($secondary, 5%)

  &-outline-secondary
    border: 1.5px solid $secondary
    color: $secondary
    background: rgba($secondary, 0)

    &:hover
      color: $white
      background: rgba($secondary, 1)

  &-dark
    color: #FFF
    background: #030309

    &:hover
      background: darken($dark, 5%)

  &-outline-dark
    border: 1.5px solid $dark
    color: $dark
    background: rgba($dark, 0)

    &:hover
      color: $light
      background: rgba($dark, 1)

  &-light
    color: $dark
    background: $light

    &:hover
      background: darken($light, 5%)

  &-outline-light
    border: 1.5px solid $light
    color: $light
    background: rgba($light, 0)

    &:hover
      color: $dark
      background: rgba($light, 1)

  &-clear-light
    color: $light

    &:hover
      color: $dark

  &-md
    // width: rem(116px)
    height: rem(40px)
    font-size: rem(12px)
    font-weight: 600
    font-family: "GeneralSans", "ProximaNova"
    line-height: 133%
    padding: 0 8px

  &-sm, button.v-size--small
    height: rem(24px) !important
    min-height: fit-content
    font-size: rem(13px)
    font-weight: 600
    font-family: "GeneralSans", "ProximaNova"
    padding: 4px 6px
    display: flex
    align-items: center
    justify-content: center
    flex-direction: row

  &-icon
    position: relative
    display: inline-flex
    align-items: center
    justify-content: center
    width: auto
    height: auto

    &:before
      content: ""
      position: absolute
      top: -5px
      left: -5px
      right: -5px
      bottom: -5px

    &-primary
      .fill
        fill: var(--primary)
        @include transition

      &:hover
        .fill
          fill: var(--primary-dark)

    &-secondary
      .fill
        fill: $secondary
        @include transition

      &:hover
        .fill
          fill: darken($secondary, 5%)

    &-light
      .fill
        fill: $light
        @include transition

      &:hover
        .fill
          fill: darken($light, 5%)

.btn-group
  display: flex
  flex-wrap: nowrap

  .btn
    flex: 0 0 auto
