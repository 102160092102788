.file_div
  margin-bottom: 20px
  padding: 10px
  border-color: #374359
  border-style: solid
  border-width: 1px
  align-items: center
  justify-content: center
  margin-right: 10px
  margin-left: 10px
