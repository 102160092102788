.main-header
  display: flex
  align-items: center
  height: 60px
  color: $secondary
  background: $dark

  > .container
      padding-right: 0

  .brand
    margin-right: 18px

    img
      display: block

  nav
    display: flex
    align-items: center

    ul
      display: flex
      align-items: center
      flex: 1 1 auto

      li
        position: relative
        display: flex
        align-items: center
        height: 60px
        margin-right: 37px

        &.space
          flex: 1 1 auto

        &:after
          content: ""
          position: absolute
          left: 0
          bottom: 0
          right: 0
          display: block
          width: 0
          height: 0
          margin: 0 auto
          border-style: solid
          border-width: 0
          border-color: transparent transparent $gray-2 transparent
          @include transition

        a
          font-size: rem(14px)
          text-align: center

          &:hover
            color: $light

        &.active
          &:after
            border-width: 0 10.5px 3px 10.5px

          a
            font-weight: bold
            color: $light

          svg
            .fill
              fill: $light
